import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiServices } from '../api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  idiomaActual: string = '';
  idiomaSeleccionado: string = '';
  traducciones: any = {};
  idiomas: any = {
    'espanol': {
      'title': 'Detalles de la vacante',
      'textButton': 'Postularse ahora',
      'text1': 'Rango salarial',
      'text2': 'Experiencia laboral',
      'text3': 'Esquema de trabajo',
      'text4': 'Ubicación',
      'subtitle': 'Detalles del empleo',
      'text5': 'Descripción de la vacante',
      'cargandoInfo': 'Cargando Información',
      'errorCarga': 'Ha ocurrido un error, intenta más tarde',

    },
    'ingles': {
      "title": "Job Details",
      "textButton": "Apply Now",
      "text1": "Salary Range",
      "text2": "Work Experience",
      "text3": "Work Schedule",
      "text4": "Location",
      "subtitle": "Job Details",
      "text5": "Job Description",
      'cargandoInfo': 'Loading Information',
      'errorCarga': 'An error occurred, please try again later',
    },
    'portugues': {
      "title": "Detalhes da Vaga",
      "textButton": "Candidatar-se agora",
      "text1": "Faixa Salarial",
      "text2": "Experiência Profissional",
      "text3": "Esquema de Trabalho",
      "text4": "Localização",
      "subtitle": "Detalhes do Emprego",
      "text5": "Descrição da Vaga",
      'cargandoInfo': 'Carregando Informações',
      'errorCarga': 'Ocorreu um erro, tente novamente mais tarde',
    }
  };

  translate: boolean = false;
  vacante: any = {
    "rango-salarial-maximo": "0.0",
    "rango-salarial-minimo": "0.0"
  };
  isModalOpen = false;
  ref_vacante: any;

  msgSuccess = '';
  statusLoader = 'Cargando'
  defualImage = 'assets/img-vacantes/Imagen9.png'

  constructor(private service: ApiServices, private router: Router, private spinner: NgxSpinnerService, private route: ActivatedRoute) {
    
  }

  ngOnInit(): void {;

    // this.cambiarIdioma(this.idiomaActual);
    // console.log(`Idioma inicial seleccionado: ${this.idiomaActual}`);
    const idiomaGuardado = localStorage.getItem('idiomaSeleccionado');
    if (idiomaGuardado) {
      this.cambiarIdioma(idiomaGuardado);
      console.log(`Idioma recuperado de localStorage: ${idiomaGuardado}`);
    }

    var url = window.location.href;
    var vacant;
    
    try {
      this.ref_vacante = url.split("?id=")[1];
      vacant = JSON.parse(localStorage.getItem("vacant") || "");
    }
    catch (e) {
      console.log(e)
    }


    if (this.ref_vacante) {
      this.statusLoader = this.traducciones.cargandoInfo;
      this.spinner.show();
      this.service.getVacanteByItemID(this.ref_vacante).subscribe(
        (response: any) => {
          if (response.length > 0) {
            this.vacante = response[0];
            localStorage.setItem("vacant", JSON.stringify(this.vacante));
          } else {
            this.statusLoader = this.traducciones.errorCarga;
            this.spinner.show(undefined, { 
              type: 'ball-scale-multiple', 
              color: 'red', 
            });
          }
          setTimeout(() => {
            this.spinner.hide();
          }, 1200);
        },
        error => {
          this.statusLoader = this.traducciones.errorCarga;
          this.spinner.show(undefined, { 
            type: 'ball-scale-multiple', 
            color: 'red', 
          });
          setTimeout(() => {
            this.spinner.hide();
          }, 2200);
        }
      );
      
    } else if (vacant) {
      this.vacante = vacant;
    }
    else {
      this.router.navigate(['/oportunidades-laborales']);
    }
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    this.isModalOpen = false;
  }
  onTraducir(){
    this.translate = !this.translate;
    console.log(this.translate)
  }
  goBack() {
    this.router.navigate(['/oportunidades-laborales']);
  }
  
  cambiarIdioma(lang: string) {
    this.idiomaActual = lang;
    this.traducciones = this.idiomas[lang];
    localStorage.setItem('idiomaSeleccionado', lang);

  }

}
