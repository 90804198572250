import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { RoleProfileComponent } from './role-profile/role-profile.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OportunidadesLaboralesComponent } from './oportunidades-laborales/oportunidades-laborales.component';
import { TablaComponent } from './tabla/tabla.component';
import { TablaSuccessRateGlobalComponent } from './tabla-success-rate-global/tabla-success-rate-global.component';
import { TablaPaisComponent } from './tabla-pais/tabla-pais.component';
import { TablaPaisSuccessComponent } from './tabla-pais-success/tabla-pais-success.component';
import { DetailsComponent } from './details/details.component';
import { ModalFormComponent } from './modal-form/modal-form.component';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { FormularioHeadHuntingComponent } from './formulario-head-hunting/formulario-head-hunting.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { InterceptorApiService } from './interceptor.service';
import { FooterComponent } from './footer/footer.component'
import { NgxSpinnerModule } from 'ngx-spinner';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PrivacyPolicyModalComponent } from './privacy-policy-modal/privacy-policy-modal.component';
import { PoliticasPrivacidadComponent } from './politicas-privacidad/politicas-privacidad.component';
import { TerminosCondicionesComponent } from './terminos-condiciones/terminos-condiciones.component';
import { TablaReportesComponent } from './tabla-reportes/tabla-reportes.component';
import { TablaReporteGlobalComponent } from './tabla-reporte-global/tabla-reporte-global.component';
import { TableTimetofillTwoComponent } from './table-timetofill-two/table-timetofill-two.component';
import { ReportComponent } from './report/report.component';
import { PrivacyContentComponent } from './privacy-content/privacy-content.component';
import { TermsContentComponent } from './terms-content/terms-content.component';
import { NgChartsModule } from 'ng2-charts';
import { GraficaHistoricoComponent } from './grafica-historico/grafica-historico.component';
import { BusinessCaseComponent } from './business-case/business-case.component';
import { FormDynamicComponent } from './form-dynamic/form-dynamic.component';
import { FormatoCompensacionComponent } from './formato-compensacion/formato-compensacion.component';
import { EjercicioFinanzasComponent } from './ejercicio-finanzas/ejercicio-finanzas.component';
import { ReferenciasLaboralesComponent } from './referencias-laborales/referencias-laborales.component';
import { FormatosComponent } from './formatos/formatos.component';
import { CanActivateTeam } from './active-token.service';
import { RedirectLinkedinComponent } from './redirect-linkedin/redirect-linkedin.component';
import { PoliticaPrivacidadeComponent } from './politica-privacidade/politica-privacidade.component';
import { TermosCondiComponent } from './termos-condi/termos-condi.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RoleProfileComponent,
    DashboardComponent,
    OportunidadesLaboralesComponent,
    TablaComponent,
    TablaSuccessRateGlobalComponent,
    TablaPaisComponent,
    TablaPaisSuccessComponent,
    DetailsComponent,
    ModalFormComponent,
    FormularioHeadHuntingComponent,
    FooterComponent,
    PrivacyPolicyModalComponent,
    PoliticasPrivacidadComponent,
    TerminosCondicionesComponent,
    TablaReportesComponent,
    TablaReporteGlobalComponent,
    TableTimetofillTwoComponent,
    ReportComponent,
    PrivacyContentComponent,
    TermsContentComponent,
    GraficaHistoricoComponent,
    BusinessCaseComponent,
    FormDynamicComponent,
    FormatoCompensacionComponent,
    EjercicioFinanzasComponent,
    ReferenciasLaboralesComponent,
    FormatosComponent,
    RedirectLinkedinComponent,
    PoliticaPrivacidadeComponent,
    TermosCondiComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    NgxDropzoneModule,
    HttpClientModule,
    NgSelectModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgChartsModule
  ],
  providers: [
    // ApiService,
    // HttpClientModule,
    CanActivateTeam,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptorApiService,
      multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
